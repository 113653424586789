<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok">
    <div id="printMe" style=" width: 740px; margin: 0 auto;">
      <div class="A4Container ">
        <div class="" style="padding: 0!important;">
          <div class="table" style="">
            <div class=""
                    style="height: 50px; width: 100%; font-weight: 700;text-align: center;">{{config.schoolname}}耗材申领单
            </div>
            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter">申请人</el-col>
              <el-col :span="4" class="cell flexCenter">{{DataInfo.username||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter">所在部门</el-col>
              <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{DataInfo.dept_name||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter">申请时间</el-col>
              <el-col :span="6" class="cell flexCenter">{{DataInfo.cdate||'-'}}</el-col>
            </el-row>
            <el-row class="flexStretch borderR">
              <el-col :span="4" class="cell flexCenter">申领单号</el-col>
              <el-col :span="20" class="cell flexCenter" style="padding: 0 10px;font-weight: 700;">{{DataInfo.sl_num}}</el-col>
            </el-row>
            <el-row class="flexStretch borderR borderB">
              <el-col :span="4" class="cell flexCenter">申领原因</el-col>
              <el-col :span="20" class="cell flexCenter breakWord" style="padding: 0 3px;">{{DataInfo.sl_remark||'-'}}</el-col>
            </el-row>
          </div>
        </div>


        <div class="" style="padding: 0!important;margin-top: 20px;">
          <div v-if="!DataInfo.sp_status || DataInfo.sp_status == 0 || DataInfo.sp_status == 2" class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                申领物品
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="2" class="cell flexCenter" style="">序号</el-col>
                  <el-col :span="18" class="cell flexCenter" style="">名称</el-col>
                  <el-col :span="4" class="cell flexCenter">数量</el-col>
                </el-row>

                <el-row v-for="(item, index) in ZCinfo">
                  <el-col :span="2" class="cell flexCenter">{{ index+1 }}</el-col>
                  <el-col :span="18" class="cell flexStart" style="padding: 0 3px;">{{ item.title }}</el-col>
                  <el-col :span="4" class="cell flexCenter">{{ item.slNum }}{{ item.jldw || '' }}</el-col>
                </el-row>
              </el-col>
            </el-row>


          </div>
          <div v-if="DataInfo.sp_status == 1" class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                申领物品
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="2" class="cell flexCenter" style="">序号</el-col>
                  <el-col :span="10" class="cell flexCenter" style="">名称</el-col>
                  <el-col :span="4" class="cell flexCenter" style="">品牌</el-col>
                  <el-col :span="4" class="cell flexCenter" style="">型号</el-col>
                  <el-col :span="4" class="cell flexCenter">申领数量</el-col>
                </el-row>

                <el-row v-for="(item, index) in ZCinfo" class="flexStretch">
                  <el-col :span="2" class="cell flexCenter" style="">{{ index+1 }}</el-col>
                  <el-col :span="10" class="cell flexCenter" style="padding: 0 3px;">
                    {{ item.ck_status == 1 ? '【已出库】' : '（未出库）' }}
                    {{ item.title }}
                  </el-col>
                  <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{ item.pinpai || '-'}}</el-col>
                  <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{ item.xinghao || '-'}}</el-col>
                  <el-col :span="4" class="cell flexCenter">{{ item.slNum }}{{ item.jldw || '' }}</el-col>
                </el-row>
              </el-col>
            </el-row>


          </div>
        </div>

        <div class="" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                耗材申领<br />审批流程
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="4" class="cell flexCenter" style="">审批人</el-col>
                  <el-col :span="6" class="cell flexCenter">审批时间</el-col>
                  <el-col :span="6" class="cell flexCenter">审批人签名</el-col>
                  <el-col :span="8" class="cell flexCenter">审批意见</el-col>
                </el-row>

                <el-row v-for="(spitem, spidx) in DataInfo.process_json">

                  <el-row v-for="(spuseritem, index) in spitem.sp_users" class="flexStretch"
                          v-if="(spitem.step_type=='or' && spitem.spover>0 && spuseritem.spover!=0) || !spitem.spover || spitem.spover==0 || spitem.step_type=='and'">
                    <el-col :span="4" class="cell flexCenter" style="">
                      {{ spuseritem.username ? spuseritem.username : spitem.teachers.typename }}
                    </el-col>
                    <el-col :span="6" class="cell flexCenter">{{ spuseritem.sptime }}</el-col>
                    <el-col :span="6" class="cell flexCenter" style="padding: 3px;">
                      <img v-if="spuseritem.handsign" :src="spuseritem.handsign" alt="" class="flexCenter"
                        style="max-height: 40px;max-width: 120px">
                    </el-col>
                    <el-col :span="8" class="cell flexStart breakWord" style="padding: 0 3px;">
                      {{ spuseritem.spover == 0 ? '【待审批】' : '' }}
                      {{ spuseritem.spover == 1 ? '【审批通过】' : '' }}
                      {{ spuseritem.spover == 2 ? '【审批驳回】' : '' }}
                      {{ spuseritem.spword }}
                    </el-col>
                  </el-row>
                </el-row>
              </el-col>
            </el-row>


          </div>
        </div>

        <div v-if="ckList&&ckList.length>0" class="" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                耗材出库<br />确认流程
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="2" class="cell flexCenter" style="">序号</el-col>
                  <el-col :span="8" class="cell flexCenter" style="">出库耗材</el-col>
                  <el-col :span="6" class="cell flexCenter">出库人签名</el-col>
                  <el-col :span="8" class="cell flexCenter">出库时间</el-col>
                </el-row>

                <el-row v-for="(item, index) in ckList" class="flexStretch">
                  <el-col :span="2" class="cell flexCenter" style="">{{ index+1 }}</el-col>
                  <el-col :span="8" class="cell flexCenter" style="padding: 0 3px;">{{ item.title }}</el-col>
                  <el-col :span="6" class="cell flexCenter" style="padding: 3px;">
                    <img v-if="item.signUrl" :src="item.signUrl" alt="" class="flexCenter"
                      style="max-height: 40px;max-width: 120px">
                  </el-col>
                  <el-col :span="8" class="cell flexCenter">{{ item.ck_date }}</el-col>
                </el-row>
              </el-col>
            </el-row>


          </div>
        </div>
		
		
		<div v-if="DataInfo.qs_json" class="" style="padding: 0!important;margin-top: 20px;">
		  <div class="table" style="">
		    <el-row class="flexStretch borderR borderB" style="">
		      <el-col :span="4" class="cell flexCenter" style="">
		        耗材签收<br />流程
		      </el-col>
		      <el-col :span="20" class="" style="">
		        <el-row>
		          <el-col :span="12" class="cell flexCenter" style="">申领人签名</el-col>
		         
		          <el-col :span="12" class="cell flexCenter">签收时间</el-col>
		        </el-row>
		        <el-row  class="flexStretch">
		          <el-col :span="12" class="cell flexCenter" style="padding: 3px;">
		            <img v-if="DataInfo.qs_json.qs_signurl" :src="DataInfo.qs_json.qs_signurl" alt="" class="flexCenter"
		              style="max-height: 40px;max-width: 120px">
		          </el-col>
		          <el-col :span="12" class="cell flexCenter">{{ DataInfo.qs_json.qs_date }}</el-col>
		        </el-row>
		      </el-col>
		    </el-row>
		
		
		  </div>
		</div>

      </div>
    </div>

    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">
      打印
    </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'export',
  components: {},
  data() {
    return {
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      pdfok: false,
      pdfok1: false,
      DataInfo: {},
      ckList: [],
      ZCinfo: [],
      config:{},
    }
  },
  props:['printId','pmid'],
  mounted() {
    if(this.printId){
      this.getDataList(this.printId)
      this.getConfig()
    }else {
    let id = this.getParams('id')
    let pmid = this.getParams('pmid')
    this.getDataList(id, pmid)
      this.getConfig(pmid)
    }
  },
  methods: {
    getDataList(id, pmid) {


      let _this = this
      let flag = false
      let ckList = [], arr = [],qsData={}
      this.$http.post('/api/zichan_sl_one', {
        id: id,
        pmid: pmid
      }).then(res => {
        if (res && res.data) {
          let resData = res.data
          resData.listLen = 0
          if (resData.hc_list) {
            resData.hc_list = JSON.parse(resData.hc_list)
            resData.listLen = resData.hc_list.length
            resData.desc = `${resData.dept_name}${resData.username}申领${resData.listLen}件物品`
            resData.hc_list && resData.hc_list.map(h => {
              h.ckNum = h.slNum
              h.ckd = false
              h.can_ck = false
              if (!h.ck_status || h.ck_status == 0) {

              } else if (h.ck_status == 1) {
                ckList.push(h)
              }
            })
            /*if (ckList && ckList.length > 0) {
              arr = [ckList[0]]
              ckList.map((a, idx) => {
                let namestr = arr.map((n) => n.ck_username).join(',')
                let namearr = namestr.split(',')
                let index = namearr.indexOf(a.ck_username)
                if (index > -1) {
                  if (idx > 0) arr[index].title += `,${a.title}`
                } else {
                  arr.push(a)
                }

              })
            }*/
          }

          let files = JSON.parse(resData.files)
          for (let item of files) {
            item.fileName = item.fileName.substr(item.fileName.lastIndexOf('/') + 1)
          }
          resData.files = files;
          resData.files_ck = []
          if (resData.process_json) {
            resData.process_json = JSON.parse(resData.process_json)
            for (let step of resData.process_json) {
              step.spover = 0;
              if (step.sp_users) {
                if (step.step_type == "or") {
                  for (let u of step.sp_users) {
                    if (u.spover) {
                      step.spover = 1;
                    }
                  }
                } else {
                  let allok = 0;
                  for (let u of step.sp_users) {
                    if (u.spover) {
                      allok++
                    }
                  }
                  if (allok == (step.sp_users.length && step.sp_users.length > 0)) {
                    step.spover = 1;
                  }
                }
              }
            }
          } else {
            resData.process_json = []
          }
          if (resData.qs_json) {
            resData.qs_json = JSON.parse(resData.qs_json)
          }
          if (resData.cdate) {
            resData.cdate = resData.cdate.substr(0, 16)
          }
         
          this.$nextTick(() => {
            _this.DataInfo = resData
            _this.ckList = ckList//arr
            _this.ZCinfo = resData.hc_list
            setTimeout(() => {
              _this.pdfok = true
              console.log("强制刷新")
              _this.$forceUpdate()
            }, 800)
          })

        }

      })

    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    getConfig(pmid) {
      let _this = this
      let config = {}
      this.$http.post("/api/sys_dictionary_list",{pmid:pmid?pmid:''}).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.map(a => {
            if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
              let json_Arr = JSON.parse(a.key_values)
              for (let item of json_Arr) {
                config[item.field] = item.value
              }
              this.config = config
              this.$nextTick(() => {
                _this.pdfok1 = true
                console.log("强制刷新")
                _this.$forceUpdate()
              })
            }
          })
        }
      })
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>

<style lang="less" type="text/less" scoped>
#printMe {
  font-size: 14px;
  color: #303133;
  box-sizing: border-box;
  //border: 1px solid red;
  div {
    box-sizing: border-box;
  }
}

.form_label {
  font-size: 16px;
  white-space: nowrap;
}

.form_input {
  margin-left: 10px;
  flex: 1;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #303133;
}


.A4Container {
  width: 740px;
  //height: 1000px;
  margin: auto;
  page-break-after: always;
  //border: 1px solid red;
  padding: 20px 40px 20px;


  .title {
    font-size: 20px;
    line-height: 50px;
  }


  .content {
    width: 100%;
    height: 100%;
    padding: 10px;
    white-space: break-spaces;
    word-break: break-all;
    overflow: hidden;
  }


  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 30px;
    font-size: 14px;
    color: #303133;
    word-break: break-word;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
  }
}
</style>
